<template>
 <div> 

   <!--start Banner section-->
  <section class="bannersc-identity">
    <div class="container">
      <div class="row">
        <div class="col-md-6 order-1 order-md-12">
          <div class="banner-text">
            <h1 v-html="$t('ourIdentity.pageTitle')"></h1> 
           <div v-html="$t('ourIdentity.subTitle')"></div>
          </div>
        </div>
        <div class="col-md-6 order-12 order-md-1">
          <div class="bannercircle_img">  
            <img src="@/assets/images/id-1.png" alt=""> 
          </div>
        </div>
      </div>
    </div>
  </section>
  <!--END  -->
 
 <section class="zigzag">
   <div class="container">
     <div class="row">
       <div class="col-md-12">
         <h3>{{$t('ourIdentity.numberSpotsTitle')}}</h3>
       </div>
     </div>
     <div class="row">
       <div class="col-md-6">
         <div class="identityBox ibox1">
           <img src="@/assets/images/id-number-1.svg" alt="">
           <h4>{{$t('ourIdentity.spots1')}}</h4>
         </div>
       </div>
       <div class="col-md-6">
         <div class="identityBox ibox2">
           <img src="@/assets/images/id-number-2.svg" alt="">
           <h4>{{$t('ourIdentity.spots2')}}</h4>
         </div>
       </div>
       <div class="col-md-6">
         <div class="identityBox ibox3">
           <img src="@/assets/images/id-number-3.svg" alt="">
           <h4>{{$t('ourIdentity.spots3')}}</h4>
         </div>
       </div>
       <div class="col-md-6">
         <div class="identityBox ibox4">
           <img src="@/assets/images/id-number-4.svg" alt="">
           <h4>{{$t('ourIdentity.spots4')}}</h4>
         </div>
       </div>
     </div>
   </div>
 </section>

  <section class="whyus_sc">
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <div class="whyscimg">
            <img src="@/assets/images/id-2.png" alt="">
          </div>
        </div>
        <div class="col-md-6">
          <h3>{{$t('ourIdentity.weAreTitle')}}</h3>
           <div v-html="$t('ourIdentity.weAreContent')"></div>
        </div>
      </div>
    </div>
  </section>    


  <section class="whyus_sc bg8">
    <div class="container">
      <div class="row">
         <div class="col-md-6 order-md-frist order-last">
          <h3>{{$t('ourIdentity.connectTitle')}}</h3>
          <div v-html="$t('ourIdentity.connectText')"></div>
        </div>
        <div class="col-md-6 order-md-last order-frist">
          <div class="whyscimg">
            <img src="@/assets/images/id-shape-1.svg" alt="">
          </div>
        </div>
       
      </div>
    </div>
  </section>    


  <section class="whyus_sc bg7">
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <div class="whyscimg">
            <img src="@/assets/images/id-3.png" alt="">
          </div>
        </div>
        <div class="col-md-6">
          <h3>{{$t('ourIdentity.teamTitle')}}</h3>
          <div v-html="$t('ourIdentity.teamContent')"></div>
        </div>
      </div>
    </div>
  </section>    



 </div>
</template>

<script>
import IdentityLayout from '@/layouts/Identity_Layout.vue' 
  export default {
    //this for layout call
        created() {
            this.$parent.$emit('update:layout', IdentityLayout);
        },
        //this for layout render
        render() {
            return this.$slots.default[0];
        }, 
     //Meta info title + description + others meta
    metaInfo() {
          return {  
                title: this.$t('metaInfo.identityTitle'),
                 htmlAttrs: {
                  lang: this.$i18n.locale,
                },
                meta: [
                    { name: 'description', content:  this.$t('metaInfo.identitydesc')}  
                ] 
          }
      }
     //end meta info
  }
</script>

<style lang="scss" scoped>

</style>