<template>
    <div class="bgcolorsc">
         <!--Header Components-->
        <HeaderTwo/>
        <!--End Footer Components--> 
        <slot/>
        <!--Header Components-->
        <Footer/>
        <!--End Footer Components-->
    </div>
</template>

<script>
import HeaderTwo from '@/components/HeaderTwo.vue'  
import Footer from '@/components/Footer.vue' 
    export default {
         components: { 
            HeaderTwo,
            Footer 
        },
        data() {
            return {
            menuOpen: false,
            }
        },
        methods: {
            toggleMenu() {
            this.menuOpen = !this.menuOpen
            }
        }
    }
</script>

<style lang="scss" scoped>
</style>